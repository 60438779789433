import React from "react"
import PropTypes from 'prop-types'
import Layout from "../../components/Layout"
import { graphql } from 'gatsby'
import SEO from '../../components/seo'
import SchoolPageList from './SchoolPageList'
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs'
import AreasOfInterestBlock from '../../pages/Homepage/AreasOfInterestBlock'

const BasicPage = ({
    data,
    pageContext: {
     breadcrumb: { crumbs },
    },
}) => {
  const {basicPage: page} = data;

  return (
      <Layout>
        <SEO title={page.metaTags.title} description={page.metaTags.description} robots={page.metaTags.robots} />

        <CustomBreadcrumbs crumbs={crumbs} path={data.path} title={data.title}/>

        <h1>{page.title}</h1>

        <div className="intro" dangerouslySetInnerHTML={{__html: page.intro}}/>

        <AreasOfInterestBlock inContent />

        <div className="page-body" dangerouslySetInnerHTML={{__html: page.body}}/>

        { page.path === "studentcolleges.html" && <SchoolPageList /> }
      </Layout>
  )
};

BasicPage.propTypes = {
  data: PropTypes.shape({
    basicPage: PropTypes.object
  })
};

export default BasicPage

export const pageQuery = graphql`
    query($id: String!) {
        basicPage(nid: {eq: $id}) {
            type
            title
            metaTags {
                title
                description
                robots
            }
            intro
            body
            path
        }
    }
`;

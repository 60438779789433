import React from "react"
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import ArrowLink from '../../../components/ArrowLink'
import styles from './styles.module.scss'

const SchoolPageList = ({data}) => {
  const schoolPagesList = data.allSchoolPage.edges;
  
  return (
      <section className={styles.schoolPagesList}>
        <h2 className={styles.heading}>
          Frequently Asked Questions About Schools
        </h2>
        
        <div className={styles.linksWrapper}>
          { schoolPagesList.map((item, index) => (
            <ArrowLink
                linkTitle={item.node.title}
                linkPath={item.node.path}
                key={index}
            />
          ))}
        </div>
      </section>
  )
};

SchoolPageList.defaultProps = {
  data: PropTypes.shape({
    allSchoolPage: {
      edges: []
    }
  })
};

SchoolPageList.propTypes = {
  data: PropTypes.shape({
    allSchoolPage: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.exact({
        node: {
          title: PropTypes.String,
          path: PropTypes.String
        }
      }))
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query SchoolPageListQuery {
        allSchoolPage(filter: {isPublished: {ne: false}}) {
            edges {
                node {
                    title
                    path
                }
            }
        }
      }
    `}
    render={ (data) => <SchoolPageList data={data} /> }
  />
)
